<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Import" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid mb-3">       
                        <div class="grid">
                            <div class="lg:col-5 md:col-12 sm:col-12 align-items-center justify-content-center">
                                <h5>Panduan</h5>
                                <ol>
                                    <li>
                                        <p class="line-height-3 m-0">Silahkan download template terlebih dahulu</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan file upload excel atau csv</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan ukuran file tidak lebih dari 2MB</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Pastikan pada penulisan tanggal sesuai format berikut: YYYY-MM-DD contoh 2023-12-28</p>
                                    </li>
                                    <li>
                                        <p class="line-height-3 m-0">Jika ingin update harga pastikan Kolom Harga terisi</p>
                                    </li>
                                </ol>
                                <div class="line-height-3 m-0" style="color:red;">Catatan: ikuti petunjuk langkah demi langkah dengan baik. Sehingga data akan terupload</div>
                                <h5>Download Template</h5>
                                <div class="p-fluid">
                                    <div class="formgrid grid">
                                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                            <div class="field mt-2 ml-2">
                                                <Button :loading="loadingDownloadTemplate" label="Download" icon="pi pi-download" class="p-button-success my-1" @click="downloadTemplate('xlsx')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="lg:col-1">
                                <Divider layout="vertical">
                                    <!-- <b>OR</b> -->
                                </Divider>
                            </div>

                            <div class="sm:col-12 md:col-12 lg:col-6">
                               <BlockUI :blocked="blockPanel">
                                    <form>
                                        <div class="p-fluid">
                                            <div class="formgrid grid">
                                                <div class="field col-12 md:col-12 lg:col-6">
                                                    <div v-if="dist">
                                                        <b>DISTRIBUTOR : {{user?.masterDistributor?.dist_code}} - {{user?.masterDistributor?.dist_name}}</b>
                                                    </div>
                                                    <div v-else>
                                                        <label for="dist_code">Distributor</label>
                                                        <Dropdown id="dist_code" v-model="formsImport.dist_code" :loading="loadingDropdownDistributorImport" :options="dataDropdownDistributorImport" optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event ,'import')"/>
                                                        <small class="p-invalid" style="color: red" v-if="errors.dist_code" >{{ errors.dist_code[0] }}</small >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <FileUpload ref="fileUpload" name="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" chooseLabel="Pilih File" uploadLabel="Unggah" cancelLabel="Batal" :customUpload="true" @uploader="onUpload" :fileLimit="1" :maxFileSize="2000000">
                                        <template #empty>
                                            <p>Seret dan taruh file disini untuk mengunggah.</p>
                                            <ProgressBar mode="indeterminate" style="height: .5em" v-if="blockPanel"/>
                                        </template>
                                        </FileUpload>
                                    </form>
                                </BlockUI>
                            </div>
                        </div>
                    </div>
                </Fieldset>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-12 lg:col-12">
                                <div v-if="dist">
                                    <b>DISTRIBUTOR : {{user?.masterDistributor?.dist_code}} - {{user?.masterDistributor?.dist_name}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3" v-if="!dist">
                                <label for="dist_code">Distributor</label>
                                <Dropdown id="dist_code" v-model="forms.dist_code" :loading="loadingDropdownDistributorAdd" :options="dataDropdownDistributorAdd" optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor" :class="{ 'p-invalid': errorAdd.dist_code }" :filter="true" :showClear="true" @filter="searchDropdownDistributor($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.dist_code" >{{ errorAdd.dist_code[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="product">Produk</label>
                                <Dropdown id="product_id" v-model="forms.product_id" :loading="loadingDropdownProductAdd" :options="dataDropdownProductAdd" optionLabel="label" optionValue="product_id" placeholder="Pilih Produk" :class="{ 'p-invalid': errorAdd.product_id }" :filter="true" :showClear="true" @filter="searchDropdownProduct($event ,'add')"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.product_id" >{{ errorAdd.product_id[0] }}</small >
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="harga">Harga</label>
                                <InputText id="harga" v-model="forms.harga" type="number" min="0" required="true" autofocus :class="{ 'p-invalid': errorAdd.harga }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.harga" >{{ errorAdd.harga[0] }}</small >
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="period">Period</label>
                                <Calendar selectionMode="range"  :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true" :class="{ 'p-invalid': errorAdd.tanggal_mulai || errorAdd.tanggal_akhir }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.tanggal_mulai || errorAdd.tanggal_akhir" >Invalid Date</small >
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownBrand">Brand</label>
                                <Dropdown v-model="filters.brand_id" :loading="loadingDropdownBrandFilter" :options="dataDropdownBrandFilter" optionLabel="brand_name" optionValue="brand_id" placeholder="Pilih Brand" :filter="true" :showClear="true" @filter="searchDropdownBrand($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="cust_id" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                            {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="dist_code" header="Kode Distributor">
                        <template #body="slotProps">
                            {{slotProps.data.dist_code}}
                        </template>
                    </Column>
                    <Column field="dist_name" header="Nama Distributor">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_distributor !== null"> 
                                {{slotProps.data.master_distributor.dist_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="region_name" header="Regional">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_distributor !== null && slotProps.data.master_distributor.master_region !== null" > 
                                {{slotProps.data.master_distributor.master_region.region_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="brand_name" header="Brand">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_product !== null && slotProps.data.master_product.master_brand !== null" > 
                                {{slotProps.data.master_product.master_brand.brand_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="product_name" header="Produk">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_product !== null"> 
                                {{slotProps.data.master_product.product_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="ukuran__gramasi" header="Gramasi">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_product !== null"> 
                                {{slotProps.data.master_product.ukuran_gramasi}}
                            </div>
                        </template>
                    </Column>
                    <Column field="pcs_per_ball" header="PCS/Ball">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.master_product !== null"> 
                                {{slotProps.data.master_product.pcs_per_ball}}
                            </div>
                        </template>
                    </Column>
                    <Column field="price1" header="Harga" bodyStyle="text-align: right">
                        <template #body="slotProps">
                            {{formatRupiah.format(slotProps.data.price1)}}
                        </template>
                    </Column>
                    <Column field="date_start" header="Tanggal Mulai">
                        <template #body="slotProps">
                            {{slotProps.data.date_start}}
                        </template>
                    </Column>
                    <Column field="date_end" header="Tanggal Akhir">
                        <template #body="slotProps">
                            {{slotProps.data.date_end}}
                        </template>
                    </Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingDownloadTemplate: false,
            loadingDropdownBrandFilter: false,
            loadingDropdownDistributorImport: false,
            loadingDropdownDistributorAdd: false,
            loadingDropdownProductAdd: false,
            loadingExcel: false,
            loadingCsv: false,
            blockPanel: false,

            // upload
            file: null,
            formsImport: {
                dist_code: null,
            },

            // dataDropdown
            dataDropdownBrandFilter: null,
            dataDropdownDistributorImport: null,
            dataDropdownDistributorAdd: null,
            dataDropdownProductAdd: null,
            
            // filter
            filters: {
                brand_id: null,
            },

            // addNew
            forms: {
                product_id: null,
                harga: null,
                dist_code: null,
            },
            period: [new Date(), new Date()],

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
	formatRupiah: null,
    created() {
		this.formatRupiah = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
	},
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownBrand('');
        this.searchDropdownDistributor('');
        this.searchDropdownProduct('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorAdd']),
		...mapGetters("auth", ["user"]),
		...mapGetters("auth", ["permission"]),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        dist(){
            return this.user?.dist_code;
        }
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownBrand(event, purpose, valueEdit){
            setTimeout(() => {
                
                if(valueEdit){
                    this.$refs.dbrnd.filterValue = valueEdit;
                }
                
                if(purpose == "filter"){
                    this.loadingDropdownBrandFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-brand',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {
                    if(purpose == "filter"){
                        this.dataDropdownBrandFilter = res.data.data;
                        this.loadingDropdownBrandFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownBrandFilter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.ddistributor.filterValue = valueEdit;
                }
                
                if(purpose == "import"){
                    this.loadingDropdownDistributorImport = true;
                } else if(purpose == "add"){
                    this.loadingDropdownDistributorAdd = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "import"){
                        this.dataDropdownDistributorImport = res.data.data;
                        this.loadingDropdownDistributorImport = false;
                    }else if(purpose == "add"){
                        this.dataDropdownDistributorAdd = res.data.data;
                        this.loadingDropdownDistributorAdd = false;
                    }else if(purpose == null){
                        this.dataDropdownDistributorAdd = res.data.data;
                        this.dataDropdownDistributorImport = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownProduct(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dproduct.filterValue = valueEdit;
                }
                
                if(purpose == "add"){
                    this.loadingDropdownProductAdd = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-product',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownProductAdd = res.data.data;
                        this.loadingDropdownProductAdd = false;
                    }else if(purpose == null){
                        this.dataDropdownProductAdd = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // DOWNLOADTEMPLATE
        downloadTemplate(ext){
            this.loadingDownloadTemplate= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/upload-product/generate-template-harga-arsip',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Template Upload Produk Harga.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();

                this.loadingDownloadTemplate= false;

            })
            .catch((err) => {
                console.log(err);

                this.loadingDownloadTemplate= false;
            });
        },
        //UPLOAD
        onUpload(event) {
            this.$store.commit("setErrors", {});
            this.blockPanel = true;
            this.file = event.files[0];

            let data = new FormData();
            data.append('file', this.file);
            data.append('dist_code', this.dist ? this.dist : this.formsImport.dist_code);

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/upload-product/upload-harga-arsip',
                data: data,
                onUploadProgress: function (e) {
                     this.$refs.fileUpload.progress = parseInt(Math.round((e.loaded * 100) / e.total));
                }.bind(this)
            })
			.then(res => {
                console.log(res);
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000});
                this.$store.commit("setErrors", {});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                this.blockPanel = false;
                this.getDataTable();
                this.clearUpload();
			})
            .catch((err) => {
                console.log(err);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
                this.$refs.fileUpload.uploadedFileCount = 0;
                this.$refs.fileUpload.progress = null;
                // this.$refs.message.visible = true;
                this.blockPanel = false;
                this.clearUpload();
            });
        },
        clearUpload(){
            this.formsImport.dist_code = null;
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/upload-product/create-harga-satuan',
                data: {
                    "product_id": this.forms.product_id,
                    "dist_code": this.dist ? this.dist : this.forms.dist_code,
                    "harga": this.forms.harga,
                    "tanggal_mulai": this.period_start_label,
                    "tanggal_akhir": this.period_end_label,
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.product_id = null;
            this.forms.dist_code = null;
            this.forms.harga = null;
        },
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/upload-product/harga-arsip',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "brand_id" : this.filters.brand_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/upload-product/export-harga-arsip',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "brand_id" : this.filters.brand_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Produk Harga Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
